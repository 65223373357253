import { makeStyles, Typography } from "@material-ui/core"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import { Link } from "../components/Link"
import { PageContext } from "../interfaces/pageContext"

const useStyles = makeStyles({
  container: {
    "& a": {
      textDecoration: "underline",
    },
  },
  textUppercase: {
    textTransform: "uppercase",
  },
})

export type ImprintPageProps = PageProps<any, PageContext>

const ImprintPage: React.FC<ImprintPageProps> = (props) => {
  const classes = useStyles(props)
  const { data, location, pageContext } = props
  pageContext.title = "Impressum"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Helmet defer={false}>
        <meta name="robots" content={`noindex, noarchive, noimageindex`} />
      </Helmet>
      <div className={classes.container}>
        <Typography align="center" variant="h1">
          Impressum
        </Typography>
        <section>
          <header>
            <Typography variant="subtitle1">Unsere Anschrift</Typography>
          </header>
          <Typography paragraph={true}>
            Andreas Donig buybags.de (im Folgenden: „buybags.de“)
            <br />
            Bräugasse 10
            <br />
            94032 Passau
            <br />
            Telefon: <Link href={`tel:0157-33877653`}>0157-33877653</Link>
            <br />
            E-Mail: <Link href={`mailto:hello@buybags.de`}>hello@buybags.de</Link>
            <br />
            Internet: <Link href={`/`}>{data.site.siteMetadata.siteUrl}</Link>
            <br />
          </Typography>
          <Typography paragraph={true}>
            Inhaltlich Verantwortlicher gemäß § 1 Abs. 4 TMG, § 55 Abs. 1 RStV und § 55 Abs. 2 RStV:
            <br />
            Andreas Donig
          </Typography>
        </section>
        <section>
          <header>
            <Typography variant="subtitle1">Streitbeilegung</Typography>
          </header>
          <Typography paragraph={true}>
            Die Europäische Kommission stellt unter{" "}
            <Link href={`https://ec.europa.eu/consumers/odr/`}>
              https://ec.europa.eu/consumers/odr/
            </Link>{" "}
            eine Plattform zur Online-Streitbeilegung (OS) bereit. Zur Teilnahme an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle ist der Anbieter nicht
            verpflichtet und nicht bereit.
          </Typography>
        </section>
        <section>
          <header>
            <Typography variant="subtitle1">Haftung für Inhalte</Typography>
          </header>
          <Typography paragraph={true}>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
            diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
            wir diese Inhalte umgehend entfernen.
          </Typography>
        </section>
        <section>
          <header>
            <Typography variant="subtitle1">Haftung für Links</Typography>
          </header>
          <Typography paragraph={true}>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
            Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
            übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
            Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
            verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.
          </Typography>
        </section>
        <section>
          <header>
            <Typography variant="subtitle1">Urheberrecht</Typography>
          </header>
          <Typography paragraph={true}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
            dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
            nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
            dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
            beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
            Inhalte umgehend entfernen.
          </Typography>
        </section>
        <section>
          <header>
            <Typography variant="subtitle1">Soziale Medien</Typography>
          </header>
          <Typography paragraph={true}>
            Das Impressum gilt auch für die folgenden Social-Media-Profile:
          </Typography>
          <ul>
            <Typography component="li">
              <Link href="https://www.facebook.com/buybagsDE/" rel="noopener" target={"_blank"}>
                Facebook
              </Link>
            </Typography>
            <Typography component="li">
              <Link href="https://www.instagram.com/buybags.de/" rel="noopener" target={"_blank"}>
                Instagram
              </Link>
            </Typography>
            <Typography component="li">
              <Link href="https://www.pinterest.com/buybagsDE/" rel="noopener" target={"_blank"}>
                Pinterest
              </Link>
            </Typography>
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default ImprintPage

export const query = graphql`
  query ImprintPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
